<script setup>
 const props = defineProps({
  anchorId: {
   type: String,
   required: false,
   default: '',
  },
  image: {
   type: [Object, String],
   required: false,
  },
  cards: {
   type: Array,
   required: true,
  },
  background: {
   type: Object,
   required: false,
   default: () => ({ topColor: 'White', bottomColor: 'White' }),
  },
  attributes: {
   type: Object,
   default: () => ({}),
  },
 });

 const cardsFormatted = props.cards;
 cardsFormatted?.forEach((card) => {
  card.useButton = true;
 });

 const colorHash = {
  White: 'var(--white)',
  Gray: 'var(--gray-100)',
 };
</script>

<template>
 <section
  v-if="cardsFormatted && cardsFormatted.length"
  :id="anchorId"
  class="block block-two-up-with-text"
  tabindex="-1"
  :style="`--top:${colorHash[background?.topColor]};--bottom:${colorHash[background?.bottomColor]};`"
 >
  <div class="container" v-aos>
   <MessImage v-if="image?.src" :image="image" class="banner" />
   <ul class="card-list">
    <MessFragmentsTwoUpCard v-for="(card, index) in cardsFormatted.slice(0, 2)" :card="card" />
   </ul>
  </div>
 </section>
</template>

<style lang="scss">
 .block-two-up-with-text {
  background-color: var(--top);
  position: relative;
  overflow: clip;

  &::after {
   content: '';
   position: absolute;
   width: 100%;
   height: 50%;
   top: 50%;
   background: var(--bottom);
   z-index: 0;
  }

  .container {
   position: relative;
   z-index: 1;

   @media screen and (min-width: $mobile) {
    display: grid;
    grid-template-columns: repeat(12, 8.33%);
    grid-template-rows: 60px auto 60px;
    max-width: none;
    width: 100%;
   }

   .banner {
    display: none;

    @media screen and (min-width: $mobile) {
     display: block;
     z-index: -1;
     grid-column: 1 / span 12;
     grid-row-start: 2;
     height: 545px;
     margin-right: 0;
     margin-left: 0;
    }
   }

   .card-list {
    @media screen and (min-width: $mobile) {
     grid-column: 2 / span 10;
     grid-row: 1 / span 3;

     display: flex;
     justify-content: space-between;

     max-width: 1130px;
     width: 100%;
     margin: -30px auto;
    }
   }

   .mess-image {
    filter: grayscale(100%);
    transition: 500ms ease-in-out;
   }

   &.v-aos-entered {
    .mess-image {
     filter: none;
    }
   }
  }

  .mess-link {
   display: block;
   width: max-content;
   margin-top: 20px;
   padding: 18px 24px;
   border: 1px solid var(--red);
   border-radius: 40px;
   color: var(--red);
   font-size: calc(12 / var(--base-fs) * 1rem);
   font-weight: 900;
   letter-spacing: 1.38px;
   text-transform: uppercase;

   span {
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
   }
  }
 }
</style>
